import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      success: false,
      date: null,
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/decline`,(json)=>{
      if(json && json.error == null){
        this.setState({
          success: true,
          date: json.date,
        });
      }else if(json && json.error) {
        console.log('in here');
        this.setState({
          error: "The email address entered was not invited to this event."
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }


  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className={`registration-page-one container`} style={{maxWidth: "630px", margin: "30px 0px"}}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-header text-center">
              Thank you for confirming your response.
            </div>
            <div className="col-xs-12 att-proam-registration-section-copy text-center">
              We look forward to hosting you at a future Cisco event. If you have any questions, please contact the Cisco Executive Events Team.
              <br/>
              <br/>
            </div>
            <div className="col-xs-12 text-center">
              <a target="_blank" href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20Cisco%20Reception%20at%202024%20World%20Economic%20Forum">
                <button type="button" style={{paddingTop: "10px"}} className="sg-button sg-submit-button">
                  Contact Us
                </button>
              </a>
            </div>
          </div>
        </div>
      )
    }
    return (
      <form src="#" onSubmit={this.declineEmail} className="att-proam-registration-email-validation registration-form " style={{margin: "30px 0px"}}>
        <div className={`registration-page-one container`} style={{maxWidth: "630px"}}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-header text-center">
              Sorry, we’ll miss you.
            </div>
            <div className="col-xs-12 att-proam-registration-section-copy text-center">
              Thank you for your response. We are sorry you are unable to attend the reception. We look forward to welcoming you at a future Cisco event.
              <br/>
              <br/>
              Please provide your email address below to confirm your response.
              <br/>
            </div>
          </div>
        </div>
        <div className="att-proam-registration-email-validation-fields">
          <br/>
          <div className="label-wrapper">
            <label className="label-english">Enter Email Address *</label>
          </div>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error error-red">
            {error}
          </div>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <button
            type="button"
            onClick={this.declineEmail}
            className="sg-button sg-submit-button"
          >
            Continue
          </button>
        </div>
        <div className={`registration-page-one container`} style={{maxWidth: "630px", paddingTop: "30px"}}>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-copy text-center">
              <br/>
              If you have any questions, please contact the <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20Cisco%20Reception%20at%202024%20World%20Economic%20Forum">Cisco Executive Events Team</a>.
              <br/>
              <br/>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default RegistrationDecline;
