import React from "react";
import PropTypes from "prop-types";

class TicketDashboardBubble extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      temporaryTicketValue: null,
      temporaryTicketName: null,
      isEditing: false
    }

    this.toggleEditing=this.toggleEditing.bind(this);
    this.updateTemporaryTicketCount = this.updateTemporaryTicketCount.bind(this);
    this.updateTemporaryTicketName = this.updateTemporaryTicketName.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  toggleEditing() {
    const { isEditing } = this.state;
    this.setState({
      temporaryTicketValue: this.props.ticket.count,
      temporaryTicketName: this.props.ticket.name,
      isEditing: !isEditing
    })
  }

  saveChanges() {
    const { updateTicket, ticket } = this.props;
    const { temporaryTicketValue, temporaryTicketName } = this.state;
    updateTicket(ticket, temporaryTicketValue, temporaryTicketName)
    this.toggleEditing();
  }

  updateTemporaryTicketCount(e){
    this.setState({
      temporaryTicketValue: e.target.value||0
    })
  }

  updateTemporaryTicketName(e) {
    this.setState({
      temporaryTicketName: e.target.value||""
    })
  }

  renderSubBubble() {
    const { isEditing } = this.state;
    if(isEditing) {

    }else {
      return (
        <div onClick={this.toggleEditing} style={{backgroundColor:"rgb(116, 191, 76)", cursor: "pointer"}} className="sg-platform-report-bubble-download">
          <img src="/images/admin/ticket-edit.svg"/>
        </div>
      )
    }
  }

  renderTicketName() {
    const { ticket } = this.props;
    const { isEditing, temporaryTicketName } = this.state;
    if (isEditing) {
      return (
        <input
          className="form-control sg-tickets-dashboard-ticket-type-total-input"
          value={temporaryTicketName || ''}
          onChange={this.updateTemporaryTicketName}
        />
      );
    } else {
      return ticket.name
    }
  }

  renderInnerBubble() {
    const { ticket, participants } = this.props;
    const { temporaryTicketValue, isEditing } = this.state;
    if(isEditing) {
      return (
        <div className="sg-ticket-dashboard-count-section">
          <label> Change Total Ticket(s) </label>
          <input
            className=" form-control sg-tickets-dashboard-ticket-type-total-input"
            value={temporaryTicketValue||0}
            type="number"
            min={0}
            max={300}
            onChange={this.updateTemporaryTicketCount}
          />
          <button onClick={this.saveChanges} className="sg-ticket-dashboard-save-button"> Save </button>
          <br/>
          <button onClick={this.toggleEditing} className="sg-ticket-dashboard-save-button"> Cancel </button>
        </div>
      )

    }else {
      return (
        <div className="sg-ticket-dashboard-count-section">
          <div className="sg-tickets-dashboard-ticket-type-count">
            { ticket.tickets_registered }
          </div>
          <div className="sg-tickets-dashboard-ticket-type-total">
            of {ticket.count}
            <br/>
            Used
          </div>
        </div>
      )
    }
  }

  render() {
    const { updateTicket, participants, ticket} = this.props;
    const { isEditing } = this.state;
    return (
      <div className="col-xs-6 col-sm-3 col-lg-5ths">
        <div className="sg-platform-report-bubble-container">
          <div style={{backgroundColor:"#74BF4C"}} className="sg-platform-report-bubble">
            <div className="sg-tickets-dashboard-ticket-type-container">
              <div className="sg-tickets-dashboard-ticket-type-name">
                {this.renderTicketName()}
              </div>
              {this.renderInnerBubble()}
            </div>
          </div>
          {this.renderSubBubble()}
        </div>
      </div>
    )
  }
}

export default TicketDashboardBubble;
