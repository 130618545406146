import React from "react";
import { Formik, Field, Form } from "formik";
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import { submitRegForm, submitSurveyForm, getOrderedPages, getOrderedQuestions, isMetaDataField, splitObjectField, getFormikValue, setFormikValue, isChildEnabled, translateCSS, getInitialFormValues, getFieldName } from "../utility/forms/FormBuilderUtility";

class FormBuilder extends React.Component {
  constructor(props) {
    super(props);
    let regForm = props.form;
    this.state = {
      regForm: regForm,
      regPages: getOrderedPages(regForm),
      page: 0,
      checkError: false,
      fullPageError: '',
      emailTaken: false,
      saving: false,
      participant: props.participant
    }
    this.afterSubmit = this.afterSubmit.bind(this);
  }

  renderTester() {
    const { participant } = this.props;
    const { regPages } = this.state;
    if(participant && participant.test_flag && (participant.email||"").indexOf("@jaguardesignstudio") != -1) {
      const pages = regPages.map((page, index) => {
        return {id: index}
      })
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);

      return (
        <div className="sg-reg-page-swapper">
          {pages.map(x=>(
            <div onClick={()=>this.setState({
              page: x.id
            })}className="sg-reg-page-swapper-item">
              Go To Page {x.id + 1}
            </div>
          ))}
        </div>
      )
    }
  }

  afterSubmit(json) {
    const { page } = this.state;
    this.setState({
      page: page + 1,
      loading: false,
    })
  }

  renderConfirmationPage() {
    const { participant } = this.props;
    return (
      <div className="registration-page-one container">
        <div className="row">
          <div className="col-xs-12 att-proam-registration-section-copy" style={{color: "#000000", textAlign: "center"}}>
            <br/>
            We are delighted you will join us for this exclusive experience. We have sent a confirmation email to {" "+participant['email']}.
            <br />
            <br />
            If you have any questions, please contact the Events Team.
            <br />
            <br />
            <div className="text-center">
              <a target="_blank" href="mailto:donotemail@jaguardesignstudio.com">
                <button type="button" style={{paddingTop: "10px", marginBottom: "20px"}} className="sg-button sg-submit-button">
                  Contact Us
                </button>
              </a>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }

  submitFormButtonOnClick(formikProps) {
    if(this.isCurrentPageValid(formikProps)) {
      this.setState({
        checkError: true,
        fullPageError: '',
      })
      formikProps.handleSubmit();
    } else {
      this.setState({
        fullPageError: "Please make sure all fields have been properly filled in.",
        checkError: true,
      });
    }
  }

  renderPageSignifiers() {
    const { page, regPages } = this.state;
    if (regPages.length === page) return <></>;
    return (
      <div className="col-xs-12 registration-section-copy">
        <div className="sg-registration-header-page-signifier-container">
          {regPages.map((regPage, index) => (
            <div className={`sg-registration-header-page-signifier ${page == index ? "active" : ""}`}>
              <img
                src={`/images/pages/page-${index}${page == index ? '-active' : ""}.svg`}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderNextButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div onClick={() => this.nextPage(formikProps)}>
          <button className="sg-button sg-submit-button reg-button">
            {question.label}
          </button>
        </div>
      </div>
    );
  }

  renderPreviousButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div onClick={() => this.prevPage(formikProps)}>
          <button className="sg-button sg-submit-button reg-button">
            {question.label}
          </button>
        </div>
      </div>
    );
  }

  renderSubmitButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div onClick={() => this.submitFormButtonOnClick(formikProps)}>
          <button className="sg-button sg-submit-button reg-button">
            {question.label}
          </button>
        </div>
      </div>
    );
  }

  renderOtherButton(question, formikProps) {
    return (
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div onClick={() => { window.location = "mailto:executiverelations@cisco.com?subject=Question%20%7C%20Cisco%20Reception%20at%202024%20World%20Economic%20Forum"; }}>
          <button className="sg-button sg-submit-button reg-button">
            {question.label}
          </button>
        </div>
      </div>
    );
  }

  renderButton(question, formikProps) {
    let renderedButton = "";
    switch(question.properties.function_type) {
      case 'next_page':
        renderedButton = this.renderNextButton(question, formikProps);
        break;
      case 'previous_page':
        renderedButton = this.renderPreviousButton(question, formikProps);
        break;
      case 'submit':
        renderedButton = this.renderSubmitButton(question, formikProps);
        break;
      case 'other':
        renderedButton = this.renderOtherButton(question, formikProps);
        break;
      default:
        return <></>;
    }
    return renderedButton;
  }

  isFieldValid(question, formikProps) {
    const { type } = this.props;
    const splitQuestionId = splitObjectField(question.attribute_id);
    if (type === 'survey') {
      if (formikProps.values['survey'][splitQuestionId[0]] === undefined) return false;
      if (formikProps.values['survey'][splitQuestionId[0]].length < 1 ) return false;
      return true;
    }
    if (type === 'registration') {
      if (isMetaDataField(question.attribute_id)) {
        if (formikProps.values[splitQuestionId[0]]['metadata'][splitQuestionId[2]] === undefined) return false;
        if (formikProps.values[splitQuestionId[0]]['metadata'][splitQuestionId[2]] .length < 1) return false;
      } else {
        if (formikProps.values[splitQuestionId[0]][splitQuestionId[1]] === undefined) return false;
        if (formikProps.values[splitQuestionId[0]][splitQuestionId[1]].length < 1 ) return false;
      }
      return true;
    }
    return false;
  }

  isCurrentPageValid(formikProps) {
    const { page, regPages }  = this.state;
    const currentPage = regPages[page];
    let isValid = true;
    currentPage.questions.forEach( question => {
      if(this.isFieldEnabled(question, formikProps)) {
        if (question.required) {
          if (!this.isFieldValid(question, formikProps)) {
            isValid = false;
          }
        }
      }
    });
    return isValid;
  }

  nextPage(formikProps = {}){
    const { page }  = this.state;
    if(this.isCurrentPageValid(formikProps)) {
      window.scrollTo(0,0);
      this.setState({
        page: page + 1,
        checkError: false,
      })
    } else {
      this.setState({
        fullPageError: "Please make sure all fields have been properly filled in.",
        checkError: true,
      });
    }
  }

  prevPage(formikProps){
    const { page }  = this.state;
    window.scrollTo(0,0);
    this.setState({
      page: page - 1,
      fullPageError: null,
      checkError: false
    })
  }

  renderQuestionLabel(question) {
    return (
      <div className="label-wrapper">
        <label className={`label-english ${question.required ? 'required':''}`}> {question.label} </label>
      </div>
    );
  }

  renderCheckboxField(question, formikProps) {
    const { type } = this.props;
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    let value = getFormikValue(question.attribute_id, formikProps, type);

    let checkboxOptions = question.options.map(option =>
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div className="radio-option-wrapper">
          <img
            src={ value !== undefined && value.includes(option.value) ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
            style={{cursor:"pointer", marginBottom: "7px"}}
            onClick={(e) => {
              if (value) {
                let index = value.indexOf(option.value);
                if (index !== -1) {
                  value.splice(index, 1);
                } else {
                  value.push(option.value);
                }
                setFormikValue(value, question.attribute_id, formikProps, type);
              } else {
                setFormikValue([option.value], question.attribute_id, formikProps, type);
              }
            }}
          />
          <div className="radio-option-label">{option.label}</div>
        </div>
      </div>
    )

    return(
      <>
        <div className={`col-xs-12 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
          {this.renderQuestionLabel(question)}
        </div>
        {checkboxOptions}
        <div className={`col-xs-12`} />
        {
          erroring ?
          <div className="col-xs-12">
            <div className="registration-field-error">
              <>Please select an option to continue.</>
            </div>
          </div>
          :
          <></>
        }
      </>
    )
  }

  renderDateField(question, formikProps) {
    const { type } = this.props;
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    return (
      <div className={`col-xs-12 col-sm-6 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <DatePicker
          name={getFieldName(question, type)}
          selected={getFormikValue(question.attribute_id, formikProps, type)}
          onChange={value => {
            setFormikValue(value, question.attribute_id, formikProps, type);
          }}
          minDate={question.properties.minimum_date ? new Date(question.properties.minimum_date) : ''}
          maxDate={question.properties.maximum_date ? new Date(question.properties.maximum_date) : ''}
          className={`form-control ${erroring ? 'field-erroring': "" }`}
        />
      </div>
    )
  }

  renderDivider(question, formikprops) {
    return (
      <div className="col-xs-12">
        <div className="content-divider"/>
      </div>
    );
  }

  renderHTML(question, formikProps) {
    return <div dangerouslySetInnerHTML={{__html: question.content}} />;
  }

  renderParagraph(question, formikProps) {
    return (
      <div className={`col-xs-12 registration-section-copy ${question.custom_class}`} style={translateCSS(question.custom_css)} dangerouslySetInnerHTML={{__html: this.formatContent(question)}} />
    );
  }

  formatContent(question) {
    const { replacableStrings } = this.props;
    let content = question.content;
    if(replacableStrings){
      replacableStrings.forEach(replacableString => {
        content = content.replaceAll(replacableString.key, replacableString.value);
      })
    }
    return content
  }

  renderRadioField(question, formikProps) {
    const { type } = this.props;
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    let value = getFormikValue(question.attribute_id, formikProps, type);

    let radioOptions = question.options.map(option =>
      <div className={`col-xs-12 col-md-3 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        <div className="radio-option-wrapper">
          <img
            src={ value === option.value ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
            style={{cursor:"pointer", marginBottom: "7px"}}
            onClick={() => {
              setFormikValue(option.value, question.attribute_id, formikProps, type);
            }}
          />
          <div className="radio-option-label">{option.label}</div>
        </div>
      </div>
    )

    return(
      <>
        <div
          className={`col-xs-12 ${question.custom_class === 'radio-block-table' ? 'radio-block-table-label' : question.custom_class}`}
          style={translateCSS(question.custom_css)}
        >
          {this.renderQuestionLabel(question)}
        </div>
        {radioOptions}
        <div className={`col-xs-12`} />
        {
          erroring ?
          <div className="col-xs-12">
            <div className="registration-field-error">
              <>Please select an option to continue.</>
            </div>
          </div>
          :
          <></>
        }
      </>
    )
  }

  renderSelectField(question, formikProps) {
    const { type } = this.props;
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    let value = getFormikValue(question.attribute_id, formikProps, type);

    return (
      <div className={`col-xs-12 col-sm-6 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <Select
          options={question.options}
          classNamePrefix='registration-select'
          value={question.options.find(option => value === option.value)}
          onChange={selected => {
            setFormikValue(selected.value, question.attribute_id, formikProps, type);
          }}
          className={erroring ? "registration-select-erroring" : ""}
        />
      </div>
    )
  }

  renderTextField(question, formikProps) {
    const { type } = this.props;
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    return (
      <div className={`col-xs-12 col-sm-6 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <Field className={`form-control ${erroring ? 'field-erroring': "" }`} name={getFieldName(question, type)} disabled={question.disabled} />
      </div>
    )
  }

  renderTextArea(question, formikProps) {
    const { type } = this.props;
    const { checkError } = this.state;
    let erroring = checkError && !this.isFieldValid(question, formikProps);
    if(!question.required) {
      erroring = false;
    }
    return (
      <div className={`col-xs-12 ${question.custom_class}`} style={translateCSS(question.custom_css)}>
        {this.renderQuestionLabel(question)}
        <Field className={`form-control ${erroring ? 'field-erroring': "" }`} name={getFieldName(question, type)} as="textarea" />
      </div>
    )
  }

  renderTicketField(question, formikProps) {
    const { ticket_info, type } = this.props;
    const { checkError } = this.state;
    let value = getFormikValue(question.attribute_id, formikProps, type);
    const rendereredDates = ticket_info.sort((a,b)=>a.id - b.id).map(ticket=> (
      <div onClick={()=> {
        if(ticket.available) {
          const { participant } = formikProps.values;
          setFormikValue(ticket.slug, question.attribute_id, formikProps, type);
        }
      }} disabled={!ticket.available} className={`sg-masters-date-select-item ${value == ticket.slug ? "active" : ""} ${!ticket.available ? "disabled" : ""} `}>
        <div className="sg-masters-date-select-item-inner">
          <img src={`/registration/days/${ticket.slug}${value == ticket.slug ? "" : "-active"}.png`} className="sg-masters-date-select-item-image"/>
          <div className="sg-masters-date-select-item-copy">
            <b>{ticket.name}</b>
          </div>
          <img src={`/registration/days/checkmark.png`} className="sg-masters-date-select-checkmark-image"/>
          <div className="sg-masters-date-select-item-disabled-message">
            No longer available
          </div>
        </div>
      </div>
    ))
    return (
      <>
      <div className="col-xs-12 sg-registration-h3-copy">
        Tournament Date
      </div>
      <div className="col-xs-12 sg-registration-large-copy">
        Please select the tournament date you would like to attend.
      </div>
      <div className="col-xs-12">
        <div className="sg-masters-date-select-item-container">
          {rendereredDates}
        </div>
        <div className="registration-field-error">
          {checkError && !this.isCurrentPageValid(formikProps) && ticket_info.map(ticket => ticket.slug).indexOf(value) == -1 ? "Please select an option to continue." : ""}
        </div>
      </div>
      <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
      </div>
      </>
    )
  }


  isFieldEnabled(question, formikProps) {
    let isEnabled = true;
    if (question.use_parent_child) {
      isEnabled = isChildEnabled(question, formikProps)
    }
    if (question.hidden) {
      isEnabled = false;
    }
    return isEnabled;
  }

  renderPage(formPage, formikProps) {
    const regQuestions = getOrderedQuestions(formPage);
    const renderedQuestions = regQuestions.map(question => {
      if(this.isFieldEnabled(question, formikProps)) {
        let renderedQuestion = "";
        switch (question.question_type) {
          case "button":
            renderedQuestion = this.renderButton(question, formikProps);
            break;
          case "checkbox":
            renderedQuestion = this.renderCheckboxField(question, formikProps);
            break;
          case "date":
            renderedQuestion = this.renderDateField(question, formikProps);
            break;
          case "divider":
            renderedQuestion = this.renderDivider(question, formikProps);
            break;
          case "html":
            renderedQuestion = this.renderHTML(question, formikProps);
            break;
          case "page_signifiers":
            renderedQuestion = this.renderPageSignifiers()
            break;
          case "paragraph":
            renderedQuestion = this.renderParagraph(question, formikProps);
            break;
          case "radio":
            renderedQuestion = this.renderRadioField(question, formikProps);
            break;
          case "select":
            renderedQuestion = this.renderSelectField(question, formikProps);
            break;
          case "text":
            renderedQuestion = this.renderTextField(question, formikProps);
            break;
          case "textarea":
            renderedQuestion = this.renderTextArea(question, formikProps);
            break;
          case "tickets":
            renderedQuestion = this.renderTicketField(question, formikProps);
            break;
          default:
            return <></>;
        }
        return (
          <>{renderedQuestion}</>
        )
      }
      return <></>;
    });

    return (
      <>
        <div className="container">
          <div className="row">
            {renderedQuestions}
            {this.renderFullPageError(formikProps)}
          </div>
        </div>
      </>
    )
  }

  renderFullPageError(formikProps) {
    const { checkError, fullPageError } = this.state;
    if (checkError && fullPageError != '' && !this.isCurrentPageValid(formikProps)) {
      return (
        <div className="col-xs-12 text-center">
          <div className="registration-field-error text-center">
            {fullPageError}
          </div>
        </div>
      )
    }
    return <></>;
  }

  renderPages(formikProps) {
    const { regForm, page, regPages } = this.state;
    if (regPages.length >= page) {
      // Reg Pages
      return this.renderPage(regPages[page], formikProps);
    } else {
      // Page out of bounds
      return <>Page out of index</>
    }
  }

  getInitialRegValues() {
    const { participant } = this.props;
    const { regPages } = this.state;
    let initialValues = participant !== undefined ? getInitialFormValues(participant) : {participant: {metadata: {}}};

    // Set default values
    regPages.forEach(page => {
      page.questions.forEach(question => {
        if (question.default_value !== '') {
          const splitQuestionId = splitObjectField(question.attribute_id);
          if (isMetaDataField(question.attribute_id)) {
            if (initialValues["participant"]["metadata"][splitQuestionId[2]] === undefined || initialValues["participant"]["metadata"][splitQuestionId[2]] === '') {
              initialValues["participant"]["metadata"][splitQuestionId[2]] = question.default_value;
            }
          } else {
            if (initialValues["participant"][splitQuestionId[1]] === undefined || initialValues["participant"][splitQuestionId[1]] === '') {
              initialValues["participant"][splitQuestionId[1]] = question.default_value;
            }
          }
        }
      });
    });
    return initialValues;
  }

  getInitialSurveyValues() {
    const { regPages } = this.state;
    let initialValues = {
      survey: {}
    };
    regPages.forEach(page => {
      page.questions.forEach(question => {
        if (question.default_value !== '') {
          const splitQuestionId = splitObjectField(question.attribute_id);
          initialValues['survey'][splitQuestionId[0]] = question.default_value;
        }
      });
    });
    return initialValues;
  }

  getInitialValues() {
    const { type } = this.props;
    if (type === 'registration') {
      return this.getInitialRegValues();
    }
    if (type === 'survey') {
      return this.getInitialSurveyValues();
    }
    return {};
  }

  render() {
    const { form, submitUrl, submitCallback, type } = this.props;
    return (
      <div className="registration-form">
        {this.renderTester()}
        <Formik
          initialValues={this.getInitialValues()}
          onSubmit={(values, actions) => {
            if (type === 'survey') {
              submitSurveyForm(values, this.state, this, form.gid, submitUrl, (json) => {submitCallback(json)})
            }
            if (type === 'registration') {
              submitRegForm(values, this.state, this, form.gid, submitUrl, (json) => {submitCallback(json)})
            }
          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0", paddingBottom: "20px"}}>
              {this.renderPages(formikProps)}
            </Form>
        )}/>
      </div>
    );
  }
}

export default FormBuilder;
