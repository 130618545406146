import React from "react";
import PropTypes from "prop-types";
import GeneralTable from "../GeneralTable"
// import AdminParticipantModal from "./forms/AdminParticipantModal"

class ParticipantTicketTable extends React.Component {
  constructor(props) {
    super(props);
    this.renderActions = this.renderActions.bind(this);
  }
  getTableColumns() {
    const { ticketTypes, updateParticipantCount} = this.props;
    const { user } = this.props;
    let allColumns = [
      { title: 'First', field: 'lower_first_name', render: (row) => row.name_first},
      { title: 'Last', field: 'lower_last_name', render: (row) => row.name_last},
      { title: 'Attendee Type', field: 'attendee_type', render: (row) => row.role },
      { title: 'Status', field: 'status'},
    ]
    allColumns = allColumns.concat(ticketTypes.map(ticketType => {
      console.log()
      return {
        title: ticketType.name.replace("Day Program |",""),
        field: ticketType.slug,
        render: (row) => (
          <>
          {
            row.metadata.find(meta =>
              meta.field_slug === ticketType.slug) ? row.metadata.find(meta => meta.field_slug === ticketType.slug).value : 0 || 0
            }
          </>
        )
      }
    }));

    allColumns = allColumns.concat([
      {title: "Actions", sortable: false, field: "", render: this.renderActions}
    ])
    return allColumns;
  }

  renderActions(row) {
    const {participants, updateParticipantCallback, ticketTypes, fields, form } = this.props;
    return (
      <>
        {/* <AdminParticipantModal
          participant={row}
          participants={participants}
          ticketTypes={ticketTypes}
          form={form}
          // fields={fields}
          updateParticipantCallback={updateParticipantCallback}
        >
          <a href="#">Edit</a>
        </AdminParticipantModal> */}
        Edit
      </>
    )
  }

  getTableRegistrants() {
    const { participants } = this.props;
    return participants.map(x=> {
      x.lower_first_name = (x.name_first||"".toLowerCase())
      x.lower_last_name = (x.name_last||"".toLowerCase())
      x.attendee_type = (x.metadata.find(meta => meta.field_slug === "attendeetype") ? x.metadata.find(meta => meta.field_slug === "attendeetype").value : "")
      return x
    }).sort((a,b)=>b.id - a.id);
  }


  render() {
    const { ticketTypes, fields, updateParticipantCallback} = this.props;
    return (
      <div className="sg-tickets-table-container">

        <GeneralTable
          columns={this.getTableColumns()}
          data={this.getTableRegistrants()}
        />
      </div>
    )
  }
}

export default ParticipantTicketTable;
