import React from "react";
import FormBuilder from "../forms/FormBuilder";

class SurveysAMFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('am survey submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    const { participant } = this.props;
    return [
      {key: '*|EMAIL|*', value: participant.email }
    ]
  }

  render() {
    const { ticket_info, participant, form } = this.props;
    return (
      <FormBuilder
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/surveys'}
        submitCallback={this.callback}
        type={'survey'}
      />
    );
  }
}

export default SurveysAMFormWrapper;
