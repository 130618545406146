import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
class RegistrationEmailValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      error: null,
      emailValue: "",
      showDecline: false,
      showRegistered: false
    }
    this.verifyEmail = this.verifyEmail.bind(this);
  }


  verifyEmail(e) {
    const { test } = this.props;
    e.preventDefault()
    const { features } = this.props;
    const { emailValue, selectedDate} = this.state;
    if(features &&  features.pick_a_date) {
      if(selectedDate == null) {
        this.setState({
          error: "Please select a program to register for."
        })
        return;
      }
    }
    fetchAPI(`/registration/verify_email`,(json)=>{
      let error = null;
      let attendee = null;
      let declined = null;
      let registered = null;
      if(json){
        attendee = json.attendee;
        error = json.error;
        declined = json.declined;
        registered = json.registered;
      }else {

      }

      if( error ) {
        console.log({error})
        if (declined) {
          this.setState({
            showDecline: true,
            showRegistered: false,
            error: error
          });
        } else if (registered) {
          this.setState({
            showDecline: false,
            showRegistered: true,
            error: error
          });
        } else {
          this.setState({
            error: error
          });
        }

      } else if (!error && attendee) {
        if (test) {
          window.location = `/registration/test?id=${attendee}${features.pick_a_date ? "&ticket="+selectedDate.value:""}${features.sandbox ? "&sandbox=true":""}`
        } else {
          window.location = `/registration?id=${attendee}${features.pick_a_date ? "&ticket="+selectedDate.value:""}${features.sandbox ? "&sandbox=true":""}`
        }
      }else {

      }
    } ,{
      method: 'POST',
      body: {
        email: emailValue||"",
      }
    })
  }

  renderPickADate() {
    const { features } = this.props;
    const { selectedDate } = this.state;
    const options = [
      {value: 'day-5', label: 'Sunday, February 6, 2022'}
    ]
    if(features && features.pick_a_date) {
      return (
        <>
          <label>Please select the day you would like to join us from the dropdown below.</label>
          <div style={{maxWidth:"600px",margin: "auto"}}>
            <Select
              options={options}
              classNamePrefix='registration-select'
              value={selectedDate}
              onChange={(option) => {
                this.setState({
                  selectedDate: option
                })
              }}
            />
          </div>
          <br/>
        </>
      )
    }
  }

  render() {
    const { emailValue, error, showDecline, showRegistered } = this.state;
    return (
      <form onSubmit={this.verifyEmail} className="att-proam-registration-email-validation registration-form " style={{marginTop: "35px"}}>
        <div className="att-proam-registration-email-validation-copy">
          <span style={{fontSize: "18px"}}><b>You have been invited to a private reception hosted by Cisco, McLaren Racing, and Global Citizen, at the 2024 World Economic Forum.</b></span>
          <br/>
          <br/>
          This reception is by invitation-only.
          <br/>
          Please ensure the email address entered below is that of the registrant.
        </div>
        <div className="att-proam-registration-email-validation-fields">
          <br/>
          <div className="label-wrapper">
            <label className="label-english">Enter Email Address *</label>
          </div>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
          <br/>
          {this.renderPickADate()}
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error" dangerouslySetInnerHTML={{__html: error}}></div>
        ):""}
        {
          showDecline ?
          <div className="att-proam-registration-email-validation-error" style={{padding: "0px 32px"}}>
            You have previously declined, please contact the <a href="mailto:donotemail@jaguardesignstudio.com">Events Team</a> to update your registration status.
          </div>
          :
          ""
        }
        {
          showRegistered ?
          <div className="att-proam-registration-email-validation-error" style={{padding: "0px 32px"}}>
            You’re already registered! If you wish to make changes to your registration, please contact the <a href="mailto:donotemail@jaguardesignstudio.com">Events Team</a>.
          </div>
          :
          ""
        }
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <button
            type="button"
            onClick={this.verifyEmail}
            className="sg-button sg-submit-button"
          >
            Continue
          </button>
          <br />
          <br />
          <br />
        </div>
      </form>
    );
  }
}

export default RegistrationEmailValidation;
